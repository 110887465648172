// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-strapi-category-slug-js": () => import("./../../../src/pages/categories/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-categories-strapi-category-slug-js" */),
  "component---src-pages-companies-index-js": () => import("./../../../src/pages/companies/index.js" /* webpackChunkName: "component---src-pages-companies-index-js" */),
  "component---src-pages-companies-strapi-company-slug-js": () => import("./../../../src/pages/companies/{StrapiCompany.slug}.js" /* webpackChunkName: "component---src-pages-companies-strapi-company-slug-js" */),
  "component---src-pages-company-types-strapi-company-type-slug-js": () => import("./../../../src/pages/company-types/{StrapiCompanyType.slug}.js" /* webpackChunkName: "component---src-pages-company-types-strapi-company-type-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-strapi-product-slug-js": () => import("./../../../src/pages/products/{StrapiProduct.slug}.js" /* webpackChunkName: "component---src-pages-products-strapi-product-slug-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

